@font-face {
    font-family: kongress;
    src: url('../fonts/kongress.otf')
         format('opentype');
}

body {
  font-family: kongress;
}

.typed-cursor{
  opacity: 1;
  padding-right: 0.2em;
	-webkit-animation: blink 0.7s infinite;
	-moz-animation: blink 0.7s infinite;
	animation: blink 0.7s infinite;
}
@keyframes blink{
	0% { opacity:1; }
	50% { opacity:0; }
	100% { opacity:1; }
}
@-webkit-keyframes blink{
	0% { opacity:1; }
	50% { opacity:0; }
	100% { opacity:1; }
}
@-moz-keyframes blink{
	0% { opacity:1; }
	50% { opacity:0; }
	100% { opacity:1; }
}

.navbar-default {
    background-color: transparent;
    border: none;
}

.nav-up{
    position:fixed;
    top:0px;
    margin-top: 50px;
    padding:0px;
    width:100vw;
 }

.nav-down{
    position:fixed;
    bottom:0px;
    width:100vw;
 }

.btn-hover {
  opacity: 0;
  color: rgba(0,0,0,.75);
  background-color: rgba(0,0,0,0);
}

.btn-hover:hover {
  opacity: 1;
}
